import React, { Component } from "react";
import './payment.css';
class Payment extends Component{
    state = {
        data: []
    };

    render() {

        return (

            <div className="payment">

                <h2 className="header" ><span
                   ><strong>Способы оплаты:</strong></span></h2>
                <p><strong>Банковской картой онлайн</strong> - оплата банковской картой на сайте.</p>
                <p>Оплата происходит через <strong>ПАО ЮMoney</strong> с использованием Банковских карт следующих
                    платежных систем:</p>
                <p>1. МИР</p>
                <p>2. VISA International</p>
                <p>3. Mastercard Worldwide</p>
                <p>3. JCB .</p>
                <p></p>
                <p className="text">Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО ЮMoney.
                    Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с
                    использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного
                    проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure, для
                    проведения платежа также может потребоваться ввод специального пароля.</p>
                <p className="text">Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной
                    информации обеспечивается <strong>ПАО ЮMoney</strong>. Введённая информация не будет предоставлена
                    третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по
                    банковским картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa
                    Int., MasterCard Europe Sprl, JCB.</p>
                <h2 className="header" ><span
                    ><strong>Правила оплаты:</strong></span></h2>
                <p>Сервис оплаты услуг Регистратора банковской картой не является обязательным и используется Клиентом
                    Регистратора по его желанию.</p>
                <p>Тарифы на услуги Регистратора приведены на странице: <a href="https://www.vrk.ru/dokumenty/tarif/"
                                                                           target="_blank"
                                                                           rel="noopener noreferrer">Тарифы</a></p>
                <p className="text">Нажимая на кнопку &laquo;Оплатить банковской картой&raquo;, Вы выражаете свое согласие с настоящими
                    Правилами оплаты и подтверждаете свое намерение оплатить услуги Регистратора с помощью банковской
                    карты.</p>
                <p>При оплате услуг Регистратора банковской картой, обработка платежа происходит на авторизационной
                    странице <strong>ПАО ЮMoney</strong>, где Вам необходимо ввести данные Вашей банковской карты:</p>
                <p>1. тип карты</p>
                <p>2. номер карты</p>
                <p>3. срок действия карты (указан на лицевой стороне карты)</p>
                <p>4. Имя держателя карты (латинскими буквами, точно также как указано на карте)</p>
                <p>5. CVC2/CVV2 код</p>
            </div>
        );
    }
}
export default Payment;
