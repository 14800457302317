import React, { Component } from "react";
import DemoTwo from "./Demon";
import DemoThree from "./Demon3";
import PdfSignedPreview from "./pdfSignedPreview";

class PdfPreview extends Component {
        constructor(props) {
        super(props);
        this.OnSubmitForm = React.createRef();
        this.state = {
	        pdfUrl: null,
            loading: true,
            error: null,
            issuers: [],
            docs: [],
            issuer: '',
            type_ls: ['В', 'Д'],
            dataType: 'statement',
            dateType: false,
            date: '',
            date2: '',
            delivery: 'ЭЛЕКТРОННЫЙ ДОКУМЕНТ',
            login: false,
            hash_code: undefined,
            input_hash: '',
            have_hash: false,
            have_pdf: false,
            cur_type_ls: undefined,
            first_log: true,
            page: 0,
            pages_count: 1,
            no_payment: 0,
            finiteUrl: '',
            showModal: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.RefreshData = this.RefreshData.bind(this);
        this.submClick = this.submClick.bind(this);
    }

    componentDidMount() {
        this.fetchPdfNotSign();
    }

    fetchPdfNotSign = async () => {
        var request = JSON.parse(window.sessionStorage.request);
        const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    try {
        const response = await fetch(`${API_ADDRESS}order_for_statements_not_signed/`, {
            method: 'POST',
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        });

        if (response.ok) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            this.setState({ pdfUrl: url, loading: false });
        } else {
            this.setState({ loading: false, error: 'Error while fetching PDF' });
        }
    } catch (error) {
        this.setState({ loading: false, error: 'Error occurred' });
    }
};

    getCode() {
    this.closeModal();
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    const REACT_APP_ESIA_REDIRECT_LOGOUT_URL=process.env.REACT_APP_ESIA_REDIRECT_LOGOUT_URL;
    try{
    fetch(`${API_ADDRESS}code/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`
      }
      }).then(res => res.json())
      .then(
        (result) => {

          if(result !== undefined){
            this.setState({
              hash_code: result[23]+result[24]+result[25]+result[26]+result[27],
              have_hash: true,
            })
          }
          else{
            window.location.reload();
          }

        });
      }
   catch(error)  {
                        localStorage.removeItem('token');
                        this.setState({ logged_in: false, username: '' });
                        this.setState({displayed_form:'login'});
                        clearInterval(this.timerID);
                        if(this.state.esia_in === 'true')
                        {
                            this.setState({esia_in: false})
                            localStorage.setItem('esia_login', false);

                            window.location.href=`${REACT_APP_ESIA_REDIRECT_LOGOUT_URL}`;
                        };

}
   }

   checkCode() {
      if(this.state.hash_code === this.state.input_hash){
        return true;
      }
      else{
        return false;
      }
    }

   async RefreshData() {
        try {
            const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
            const res = await fetch(`${API_ADDRESS}inf/`, {
                headers: {
                    Authorization: `JWT ${localStorage.getItem('token')}`
                }
            });
            console.log("RefreshData called");
            const docs = await res.json();
            var pages_qantity = Math.ceil(docs.documents.length / 10);
            this.setState({
                docs: docs,
                pages_count: pages_qantity
            });

        } catch (e) {
            console.log(e);
        }
    }

   async handleChange(event) {
    const options = { year: "numeric",
                      month: "2-digit",
                      day: "numeric"};
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "date" || name === "date2"){
      const res = await fetch(`${process.env.REACT_APP_API_ADDRESS}doc_date/?emit=`+this.state.issuer)
      .then((response) => {return response.json()});
      var dt_end = new Date();
      localStorage.setItem('dt_end', 'текущее время')
      if (res.dt_end) {
        dt_end = new Date(res.dt_end);
        localStorage.setItem('dt_end', dt_end.toLocaleDateString("ru", options));
      }
      var dt_start = new Date(res.dt_start);
      var input_date = new Date(value);
      localStorage.setItem('dt_start', dt_start.toLocaleDateString("ru", options));
      if (dt_end <= input_date){
        document.querySelector(".bad_alert_bttn_3").click();
        target.value = undefined;
      }
      else if (dt_start >= input_date){
        document.querySelector(".bad_alert_bttn_3").click();
        target.value = undefined;
      }
      else{
        this.setState({
          [name]: value
        });
      }
    }
    else{
      this.setState({
        [name]: value
      });
    }
  }

  submitClick() {
        if (this.checkCode) {
            this.OnSubmitForm.current.dispatchEvent(new Event("submit"));
        }
    }

  submClick() {
        this.setState({ showModal: true });
    }

    closeModal = () => {
        this.setState({ showModal: false });
    }

    render() {
        const { pdfUrl, loading, error, showModal } = this.state;

        return (
            <div>
                <h2 style={{textAlign: "center"}}>
                    РАСПОРЯЖЕНИЕ НА ВЫДАЧУ ВЫПИСКИ / СПРАВКИ
                </h2>
                <div>
                    {loading && <div>Loading...</div>}
                    {error && <div>{error}</div>}
                    {pdfUrl && (
                        <div>
                            <iframe
                                title="PDF Preview"
                                src={pdfUrl}
                                width="100%"
                                height="800px"
                                style={{border: "none"}}
                            />
                        </div>
                    )}
                </div>
                <div style={styles.footer}>
                    <DemoThree
                        onClick={this.getCode.bind(this)}
                        callback={this.handleChange}
                        hash={this.checkCode.bind(this)}
                        refresh={this.RefreshData.bind(this)}
                        childOnClick={this.submClick}
                        isActive={false}
                    />
                </div>

                {showModal && (
                    <div className="modal2">
                        <div className="modal2-content">
                            <span onClick={this.closeModal} className="close2">&times;</span>
                            <PdfSignedPreview />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        height: "100vh",
        backgroundColor: "#f0f0f0",
        padding: "20px",
        boxSizing: "border-box",
    },
    pdfWrapper: {
        width: "80%",
        maxWidth: "800px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        backgroundColor: "#fff",
        overflow: "hidden",
    },
    footer: {
        display: "center",
        justifyContent: "space-between",
        padding: "15px 0",
    },
};

export default PdfPreview;