import React, { Component } from "react";
import Loading from "./loader";
import ChangePassword from "./ChangePassword";


class User_Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:''
    };
  }
    async componentDidMount() {
      try {
        const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
        const res = await fetch(`${API_ADDRESS}settings/`, {
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`
          }
        });

        const answer = await res.json();
        if(answer["detail"] === "Signature has expired."){
            alert("Время сессии истекло. Пожалуйста выполните вход.");
            document.getElementById("logoutBttn").click();
          }
        else{
          this.setState({
            data: answer
          });
        }
      } catch (e) {
        console.log(e);
      }
    }

  render() {
    var key = this.state.data[0];
    if(key !== undefined){
        return (
          <div className="wrapper1 holder middleForm">
                <div>
                  <label className="shadow"> Имя пользователя: </label>
                </div>
                <div>
                  <input type="text" disabled="disabled" value={JSON.parse(this.state.data)[0]['username']}/>
                </div>
            <div>
            </div>
            <div>
            </div>
            <div>
              <label className="shadow"> ФИО: </label>
            </div>
            <div>
              <input type="text" disabled="disabled" value={JSON.parse(this.state.data)[0]['fullname']}/>
            </div>
            <div>
              <label className="shadow"> Номер телефона: </label>
            </div>
            <div>
              <input type="text" disabled="disabled" value={JSON.parse(this.state.data)[0]['phone']}/>
            </div>
            <div>
              <label className="shadow"> Email </label>
            </div>
            <div>
              <input type="text" disabled="disabled" value={JSON.parse(this.state.data)[0]['email']}/>
            </div>

            <div>
              <ChangePassword style={''}/>
            </div>
          </div>
        );
    } else {
      return (
        <Loading/>
      );
    }
  }
}

export default User_Profile;
