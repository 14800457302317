import React from 'react';
import logo from "./images/gos_logo_mobile.svg"


class EsiaComponent extends React.Component {
  constructor(props){
        super(props);
        this.state = {answer:"",
                      speed: 2
                      };
  }

  async componentDidMount() {
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    const SELF_ADDRESS = process.env.REACT_APP_SELF_ADDRESS;
    const ESIA_REDIRECT_LOGOUT_URL = process.env.REACT_APP_ESIA_REDIRECT_LOGOUT_URL;
    var uri = window.location.href;
    var params = uri.split('?')[1];
    const res = await fetch(`${API_ADDRESS}esia_ok?`+params);
    const answer = await res.json();
    this.setState({
      answer: answer['answer']
    });
    if(answer.hasOwnProperty('token')){
      localStorage.setItem('token', answer['token']);
      localStorage.setItem('esia_login', true);
      setTimeout(() => {
        window.location.href = `${SELF_ADDRESS}`;
        //window.location.href = logout_href;
      }, 1000);
    }
    else{
      localStorage.setItem('esia_login', false);
      setTimeout(() => {
        window.location.href = `${ESIA_REDIRECT_LOGOUT_URL}`;
        //window.location.href = logout_href;
      }, 2000);
    }
  }


  render() {
    if (this.state.answer === ""){
      return (
        <>
        <div className="wrapper">
          <div className="esiaPage">
            <div className="esiaLogoWrapper">
              <img className="esiaLogoSpinning" style={{animation: `spin ${this.state.speed}s linear infinite`}} src={logo} alt="logo"/>
            </div>
            <h1 className="esiaText"> Ваш запрос обрабатывается... </h1>
          </div>
        </div>
        </>
      );
    }
    else{
      return (
        <>
        <div className="wrapper">
          <div className="esiaPage">
            <div className="esiaLogoWrapper">
              <img className="esiaLogoSpinning" src={logo} alt="logo"/>
            </div>
            <h1 className="esiaText"> {this.state.answer} </h1>
          </div>
        </div>
        </>
      );
    }

  }
}

export default EsiaComponent;
