import React, { Component } from "react";
import LazyLoad from "react-lazyload"
import Loading from "./loader";
import { positions, Provider } from "react-alert";


class Transactions extends Component {
constructor(props) {
    super(props);
    this.OnSubmitForm = React.createRef();
    this.state = {issuers:[],
                  issuer:'',
                  data:[],
                  type_ls:[],
                  have_pdf: false,
                  
                                   };
  
this.RefreshData = this.RefreshData.bind(this);
this.Showall=this.Showall.bind(this)
}
 async componentDidMount() {
    try {
      await this.RefreshData();
      const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
      const res = await fetch(`${API_ADDRESS}get_issuers/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      });

      const issuers = await res.json();
      if(res["statusText"] === "Unauthorized"){
        alert("Время сессии истекло. Пожалуйста выполните вход.");
        document.getElementById("logoutBttn").click();
      }
      this.setState({
        issuers:issuers
      });
      this.setState({
        issuer:issuers[0].id

      })
    } catch (e) {
      console.log(e);
    }
  }
 HavePdf(success) {
      this.setState({
        have_pdf:true
      })
  }
ChangePage(id){
    if(id !== this.state.page){
      this.setState({
        page: id
      });
    }
  }
async Showall(){
    try {
      const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
      const res = await fetch(`${API_ADDRESS}traninf/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      });
      console.log("Full inf called");
      const docs = await res.json();
      var pages_qantity = Math.ceil(docs.length/10);
      console.log(pages_qantity)
      console.log(docs)
      this.setState({
        docs: docs,
        pages_count: pages_qantity
      });

    } catch (e) {
      console.log(e);
    }
  }
async RefreshData() {
    try {
      const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
      const res = await fetch(`${API_ADDRESS}traninf/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      });
      console.log("RefreshData called");
      const data = await res.json();
      console.log(data)
         
      this.setState({
        data: data,

      });

    } catch (e) {
      console.log(e);
    }
  }
  render() {

var key = this.state.data[0];
if(key !== undefined){
      var data = JSON.parse(this.state.data);
    }

    const options = {
        timeout: 5000,
        position: positions.TOP_CENTER,
        transition: 'scale'
    };

   async function DownloadPDF(guid){
      var request = {guid:guid};
      console.log(request)
      const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
      const res = await fetch(`${API_ADDRESS}load_pdf_from_storage/`, {
        method: 'POST',
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      });
      const answer = await res.json();
      var pdf = answer['pdf'];
      if(pdf !== undefined){
        pdf = pdf.slice(1);
        pdf = pdf.substring(0, pdf.length - 1);
        pdf = pdf.split(',');
        pdf = pdf.map(function (el) {
          return el.trim();
        });
        var byteArray = new Uint8Array(pdf);
        var pdfData = new Blob([byteArray], {type: 'application/pdf'});
        let url = window.URL.createObjectURL(pdfData);
					let a = document.createElement('a');
					a.href = url;
					a.target='_blank';
					a.click();
        }
      }

     return (
    <div className="table-wrap">
                         
        <table className="table-cust" id="customers">
        <tbody>
            <tr>
                <th> Эмитент </th>
                <th> Описание </th>
                <th> Начало периода </th>
                <th> Конец периода </th>
                <th> Номер документа </th>
                <th> PDF Документ </th>
            </tr>
            {data?.map((item, i)  =>

              <tr key={i}>
                  <td>
                    <LazyLoad key={i} placeholder={<Loading/>}>
                      {item.issuerN}
                    </LazyLoad>
                  </td>
                    <LazyLoad key={i} placeholder={<Loading/>}>
                    Отчет по операциям за период
                    </LazyLoad>
                  <td>
                    <LazyLoad key={i} placeholder={<Loading/>}>
                        {item.dt1}
                    </LazyLoad>
                  </td>

                   <td>
                    <LazyLoad key={i} placeholder={<Loading/>}>
                        {item.dt2}
                    </LazyLoad>
                   </td>


                    <td>
                    <>
                      {item.no_out !== null ? (
                        <LazyLoad placeholder={<Loading/>}>
                        {item.no_out}
                        </LazyLoad>
                      ):(
                        <LazyLoad placeholder={<Loading/>}>
                        Отсутствует
                        </LazyLoad>
                      )}
                      </>
                    </td>

                  <td>

                  <LazyLoad key={i} placeholder={<Loading/>}>
                  {item.status === 1 ? (
                    <button className="downloadBttn" disabled>
                        Скачивание не активно
                    </button>
                  ) : (
                    <button className="downloadBttn button2" onClick={() => { DownloadPDF(item.guid); setTimeout(() => this.RefreshData(),500)}}>
                        Скачать
                    </button>
                  )
                  }

                  </LazyLoad>
                  </td>
              </tr>
            )}
        </tbody>
        </table>
        </div>
    );

  }
}
export default Transactions;