import React, { Component } from "react";
import './Exhange.css';

class Exchange extends Component{
    state = {
        data: []
    };


    render() {

        return (
            <div className='Exchange'>
                <h1 className='header'>Возврат</h1>
                <div>
                    <div class="ll1">
                        <div>
                            <p><b>Отказ от услуги, возврат денежных средств</b></p>
                            <p>В случае невозможности оказания услуги, или отмены Клиентом услуги, по которой прошла
                                оплата банковской картой, Клиент вправе потребовать возврата денежных средств в
                                соответствии со ст. 32 Закона РФ № 2300-1 «О Защите прав потребителей».</p>
                            <p>Предусмотрен следующий порядок возврата денежных средств:</p>

                                <p>1.Клиент оформляет заявление на возврат средств с информацией о том, когда проходила
                                    оплата и причине возврата
                                </p>
                                <p>2.Заполненное заявление может быть представлено в подразделение АО «ВРК» лично
                                    (требуется паспорт), либо направляется по почте по адресу: 620014, г.
                                    Екатеринбург, ул. Добролюбова, д. 16, 5 этаж.
                                </p>
                                <p>3.К заявлению, направляемому по почте, должна быть приложена копия паспорта - страницы
                                    с реквизитами паспортных данных и сведениями о последнем (действующем) адресе места
                                    Регистрации.
                                </p>
                                <p>4.По результатам рассмотрения заявления производится возврат денежных средств, либо
                                    клиент информируется о причинах отказа в их возврате.
                                </p>

                            <p>Возврат денежных производится безналичным способом на тот же карточный счет, с которого
                                проходила оплата в течение 10 (Десяти) рабочих дней со дня получения Регистратором
                                оригинала заявления.</p>
                            <p><b>Реквизиты организации:</b></p>
                            <p><b>РЕГИСТРАТОР:</b> Акционерное общество « Ведение реестров компаний»</p>
                            <p><b>Место нахождения:</b> 620014, г. Екатеринбург, ул. Добролюбова, д. 16, 5 этаж</p>
                            <p><b>Почтовый адрес:</b> 620014, г. Екатеринбург, ул. Добролюбова, д. 16, 5 этаж</p>
                            <p><b>ОГРН/ ИНН:</b> 1026605227923/6661049239</p>
                            <p><b>Телефон:</b> +7(343)283-02-16</p>
                            <p><b>Факс:</b> +7(343)283-02-19</p>
                            <p><b>КПП:</b> 667101001</p>
                            
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
export default Exchange;
