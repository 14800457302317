import AriaModal from "react-aria-modal";
import eye from "./images/eye.png"
import close from "./images/close.png"


const React = require('react');


class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalActive: false,
      password: "",
      password_check: "",
      password_is_valid: false,
      message: "Введите пароль",
      type: 'password',
      type2: 'password',
    };

    this.activateModal = this.activateModal.bind(this);
    this.deactivateModal = this.deactivateModal.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClick2 = this.handleClick2.bind(this);
    this.checkPassword = this.checkPassword.bind(this);
    this.escFunction = this.escFunction.bind(this);
  }

  activateModal = () => {
    this.setState({ modalActive: true });
  };

  deactivateModal = () => {
    this.setState({ modalActive: false });
  };

  escFunction(event){
    if(event.keyCode === 27) {
      event.stopImmediatePropagation();
    }
  }

  handleSubmit(event, data) {
    const {REACT_APP_SELF_ADDRESS, REACT_APP_API_ADDRESS, REACT_APP_ESIA_REDIRECT_LOGOUT_URL} = process.env;
    var request = {new_password:data['password']};
    event.preventDefault();
    fetch(`${REACT_APP_API_ADDRESS}change_password/`, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    });
    this.deactivateModal();
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    },
    function(){
      var result = this.checkPassword(this.state.password, this.state.password_check);
      this.setState({message:result[1]})
      if (result[0] === 1){
        this.setState({password_is_valid:true})
      }
      else{
        this.setState({password_is_valid:false})
      }
    }
    );

  }

   handleClick = () => this.setState(({type}) => ({
     type: type === 'text' ? 'password' : 'text'
   }));

   handleClick2 = () => this.setState(({type2}) => ({
     type2: type2 === 'text' ? 'password' : 'text'
   }));

  async componentDidMount() {
    const {REACT_APP_SELF_ADDRESS, REACT_APP_API_ADDRESS, REACT_APP_ESIA_REDIRECT_LOGOUT_URL} = process.env;
    document.addEventListener('keydown', this.escFunction);
    const psswd_changed = await fetch(`${REACT_APP_API_ADDRESS}psswd_changed/`, {
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`
      }
    });
    const password_changed = await psswd_changed.json()
    this.setState({
      modalActive:  !password_changed['password_changed']
    });
  }

  checkPassword(psswd, new_psswd){
    if(psswd.length < 9){
      return [0, "Пароль слишком короткий"];
    }
    var lowerCaseLetters = /[a-z]/g;
    if(psswd.match(lowerCaseLetters) === null){
      return [0, "Пароль должен содержать латиницу верхнего и нижнего регистров"];
    }
    var upperCaseLetters = /[A-Z]/g;
    if(psswd.match(upperCaseLetters) === null){
      return [0, "Пароль должен содержать латиницу верхнего и нижнего регистров"];
    }
     var numbers = /[0-9]/g;
     if(psswd.match(numbers) === null){
       return [0, "Пароль должен содержать цифры"];
     }
     if(psswd === new_psswd){
       return [1, "Отправить"];
     }
     return [0, "Введённые пароли не совпадают"];
  }

  render() {
    const modal = this.state.modalActive
      ? <AriaModal
          titleId="demo-two-title"
          onExit={this.deactivateModal}
          underlayClickExits={false}
          verticallyCenter={true}
        >

            <form onSubmit={event => this.handleSubmit(event, this.state)}>
            <img className="close-bttn img" onClick={this.deactivateModal} height="35x" width="35px" align="right" src={close} alt="close"/>
              <div id="demo-two-modal" className="modal">
                <header className="modal-header">
                  <h2 id="demo-two-title" className="modal-title">
                    Введите новый пароль
                  </h2>
                  <div className="modal-body">

                      <div className="col-75 raw-4">
                          <p></p>
                      </div>
                      <div className="col-25 raw-4">
                          <p></p>
                      </div>

                      <div className="col-50 raw-4">
                          <label htmlFor="Select" class="radioText"> Введите новый пароль </label>
                      </div>
                      <div className="col-45 raw-4">
                          <input type={this.state.type} name="password" id="password" onChange={this.handleChange}/>
                      </div>
                      <div className="col-5 raw-4" onClick={this.handleClick}>
                          <img className="iconPass img" src={eye} alt="eye"/>
                      </div>
                      <div className="col-75 raw-4">
                          <p></p>
                      </div>
                      <div className="col-25 raw-4">
                          <p></p>
                      </div>
                      <div className="col-50 raw-4">
                          <label htmlFor="Select" class="radioText"> Повторите пароль </label>
                      </div>

                      <div className="col-45 raw-4">
                          <input type={this.state.type2} name="password_check" id="password_check" onChange={this.handleChange}/>
                      </div>
                      <div className="col-5 raw-4" onClick={this.handleClick2}>
                          <img className="iconPass img" src={eye} alt="eye"/>
                      </div>
                  </div>

                </header>

                <footer className="modal-footer">
                {this.state.password_is_valid ? (
                  <button className="button2" type="submit" onClick={() => this.deactivateModal}>
                    {this.state.message}
                  </button>
                ) : (
                  <button type="submit" disabled>
                    {this.state.message}
                  </button>
                )}
                </footer>
              </div>
              </form>

        </AriaModal>
      : false;

    return (
      <div>
        <button className="button2" style={{display: this.props.style }} onClick={this.activateModal}>
          Сменить пароль
        </button>
        {modal}
      </div>
    );
  }
}
export default ChangePassword;
