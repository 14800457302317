import React, {Component, Fragment} from "react";




class SuccessNoPayment extends Component {

    noPaymentFunc() {
    var request = JSON.parse(window.sessionStorage.request);
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;

    fetch(`${API_ADDRESS}inf_no_paid/`, {
        method: 'POST',
        headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('response was not ok');
        }
        return response.json();
    })
    .then(data => {
        console.log('200');
    })
    .catch(error => {
        console.error('Произошла ошибка:', error);
    })
    .finally(() => {
        document.body.classList.remove('modal-open')
        window.history.back();
        sessionStorage.clear();
    });
}


    render() {



        return(

        <div className="Thanks" onLoad={this.noPaymentFunc()}>

        <div>
             <p align="left">Формирование. После выполнения вы будете перенаправлены на главную страницу. </p>
        </div>








        </div>
        )
    }


}
export default SuccessNoPayment;