import React from "react";
import { css } from "@emotion/core";
import { PulseLoader } from "react-spinners";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-left: 45%;
  margin-top: 10%;
`;

class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      color: getComputedStyle(document.documentElement).getPropertyValue('--base-red-color')
    };
  }

  render() {
    return (
      <div className="sweet-loading" id="sweet-loading">
        <PulseLoader
          className="loader"
          css={override}
          size={15}
          margin={2}
          //size={"150px"} this also works
          color={this.state.color}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

export default Loading;
