import React, { Fragment } from "react";
import { useAlert } from "react-alert";

const Home = () => {
  const alert = useAlert();

  return (
    <Fragment>
      <div className="alert_bttn"
        onClick={() => {
          alert.success("Запрос отправлен!");
        }}
      >
      </div>
      <div className="bad_alert_bttn"
        onClick={() => {
          alert.error("Введите дату!");
        }}
      >
      </div>
      <div className="warn_alert_bttn"
        onClick={() => {
          alert.info("Введён не верный логин или пароль.");
        }}
      >
      </div>

      <div className="bad_alert_bttn_2"
        onClick={() => {
          alert.error("Введённая дата не может быть больше текущей");
        }}
      >
      </div>

      <div className="bad_alert_bttn_3"
        onClick={() => {
          alert.error("Запрашиваемая дата не принадлежит периоду обслуживания реестра " +  localStorage.getItem('dt_start') + ' по ' +  localStorage.getItem('dt_end'));
        }}
      >
      </div>
    </Fragment>
  );
};

export default Home;
