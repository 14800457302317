import React, { Component } from "react";
import Loading from "./loader";

class Portfolio extends Component {
    state = {
      data:[]
    };

    async componentDidMount() {
      try {
        const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
        const res = await fetch(`${API_ADDRESS}portfolio/`, {
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`
          }
        });

        const data = await res.json();
        if(data["detail"] === "Signature has expired."){
            alert("Время сессии истекло. Пожалуйста выполните вход.");
            document.getElementById("logoutBttn").click();
          }

        this.setState({
          data
        });
      } catch (e) {
        console.log(e);
      }
    }

  render() {
    var key = this.state.data[0];
    if(key !== undefined){
      var data = JSON.parse(this.state.data);
    }
    if(key !== undefined){
        return (
          <div className="table-wrap">
          <table className="table-cust" id="customers">
            <tbody>
                <tr>
                      <th>Эмитент</th>
                      <th>Регистрационный номер</th>
                      <th>Наименование Цб</th>
                      <th>Количество Цб</th>
                      <th>Номер лицевого счёта</th>
                      <th>Тип лицевого счёта</th>
                </tr>
                {data.map((item, idx) => (
                  <tr key={idx}>
                    <td>{item.emit_id}</td>
                    <td>{item.reg_n}</td>
                    <td>{item.typ_cat}</td>
                    <td>{item.num_stoc}</td>
                    <td>{item.sch_id}</td>
                    <td>{item.typ_ls}</td>
                  </tr>
                ))}

              </tbody>
          </table>
          </div>
        );
    } else {
      return (
        <Loading/>
      );
    }
  }
}

export default Portfolio;
