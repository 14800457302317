import React from 'react';
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";

import PropTypes from 'prop-types';
import {Fragment} from "react";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import Home from "./Home";
import EsiaComponent from "./EsiaComponent";
import logo from "./images/gos_logo_mobile.svg";
import logoText from "./images/logo.svg";
import eye from "./images/eye.png"
import PhoneForm from "./PhoneForm";


class LoginForm extends React.Component {
  state = {
    username: '',
    password: '',
    type: 'password',
    lol:false
  };

  handle_change = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState;
    });
  };

  handleClick = () => this.setState(({type}) => ({
    type: type === 'text' ? 'password' : 'text'
  }));

  render() {
    var esia_ok = false;
    if(window.location.href.includes("?code=")){
      esia_ok = true;
    }
    const options = {
        timeout: 2000,
        position: positions.TOP_CENTER,
        transition: 'scale'
    };
    async function loginESIA(){
      const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
      const res = await fetch(`${API_ADDRESS}esia/`);
      const url = await res.json();
      window.location.href = url['url'];
    }

    if(!esia_ok){
      return (
        <form onSubmit={e => this.props.handle_login(e, this.state)}>
          <div className="raw-1">
            <div className="box">

            </div>
          </div>
          <div className="raw">
            <div className="col-35">

            </div>

            <div className="col">
              <h3 className="text-center textLogin">Войти</h3>

              {/*<div className="esiaHolder">
                  <div className="esiaBttn" onClick={loginESIA}>
                  <img className="esiaLogo" src={logo} alt={"logo"}/>
                  <h4 className="esiaText">Войти на сайт через</h4>
                  <img className="esiaLogoText" src={logoText} alt={"logoText"}/>
                  </div>
              </div>
*/}
              <div className="raw">
                  <label htmlFor="username" className="radioText">Имя Пользователя:</label><br/>
              </div>
              <div className="raw">
                  <span><input
                    type="text"
                    name="username"
                    defaultValue=""
                    value={this.state.username}
                    onChange={this.handle_change}
                  /></span>
              </div>
              <div className="raw">
                  <label htmlFor="password" className="radioText">Пароль:</label><br/>
              </div>
              <div className="raw">
                  <span>
                      <input
                        type={this.state.type}
                        name="password"
                        value={this.state.password}
                        defaultValue=""
                        onChange={this.handle_change}
                      />
                  </span>
                  <div className="iconPassHolder" onClick={this.handleClick}>
                      <img className="iconPass img" src={eye} alt="eye"/>
                  </div>
              </div>

              <div className="raw">
                  <div className="SubmitBttnLogin">
                      <Provider template={AlertTemplate} {...options}>
                      <Fragment><input type="submit" value="Войти"/></Fragment>
                      <Home/>
                      </Provider>
                  </div>
              </div>
            <HashRouter>
            <li className="textLink"><NavLink to="/password_change" className="textLink">Забыли пароль?</NavLink></li>
            <Route path="/password_change" component={PhoneForm}/>
            </HashRouter>
            </div>

          </div>
        </form>
      );
    }
    else{
        return (
          <EsiaComponent/>
        )
    }
  }
}

export default LoginForm;

LoginForm.propTypes = {
  handle_login: PropTypes.func.isRequired
};
