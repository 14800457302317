import React, { Component } from "react";
import DemoTwo from "./Demon";
import Loading from "./loader";

class PdfSignedPreview extends Component {
    constructor(props) {
    super(props);
    this.OnSubmitForm = React.createRef();
    this.state = {
        pdfUrl: null,
        loadingPDF: true,
        error: null,
        isInvoicePdfFetched: false,
        no_payment: 0
        };
    }

    async componentDidMount() {
        const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
        const userRes = await fetch(`${API_ADDRESS}current_user/`, {
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`
            }
        });
        const userData = await userRes.json();
        const no_payment = userData.no_payment;
        this.setState({
            no_payment: no_payment,
        });

        this.fetchPdfSign();
    }

    fetchPdfSign = async () => {
    const request = JSON.parse(window.sessionStorage.request);
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    this.setState({ isInvoicePdfFetched: false });

    try {
        const response = await fetch(`${API_ADDRESS}order_for_statements_signed/`, {
            method: 'POST',
            headers: {
                Authorization: `JWT ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });
        if (response.ok) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            this.setState({ pdfUrl: url });
        } else {
            throw new Error('Error while fetching PDF');
        }
        if (this.state.no_payment === 1) {
            // Если no_payment равно 1, выходим из функции без выполнения следующих запросов
            this.setState({ isInvoicePdfFetched: true });
            return;
        }
        const resInvoice = await fetch(`${API_ADDRESS}invoice/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(request),
        });
        if (!resInvoice.ok) {
            alert("Произошла ошибка в создании счета");
            throw new Error(`Error fetching invoice: ${resInvoice.status}`);
        }
        const resPDF = await fetch(`${API_ADDRESS}inv_pdf/`, {
            method: 'GET',
            headers: {
                'Authorization': `JWT ${localStorage.getItem('token')}`,
            }
        });
        if (!resPDF.ok) {
            alert("Произошла ошибка в создании счета");
            throw new Error(`Error fetching PDF: ${resPDF.status}`);
        }
        const blob = await resPDF.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        this.setState({ isInvoicePdfFetched: true });
    } catch (error) {
        console.error(error);
        this.setState({ error: error.message });
    } finally {
        this.setState({ loadingPDF: false });
    }
};

    async submitClick() {
    var request = JSON.parse(window.sessionStorage.request);
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    const res=await fetch(`${API_ADDRESS}registryYoo/`, {
      method: 'POST',
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
        body: JSON.stringify(request)
      });
        const json=await res.json();
        const url=await json['p_url'];
        window.open(url, "_self");
    }

    submClick() {
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    fetch(`${API_ADDRESS}current_user/`, {
        method: 'GET',
        headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`,
        }
    })
    .then(response => response.json())
    .then(data => {
        if (data.no_payment === 1 || data.no_payment === 2) {
            window.open('http://' + data.finiteUrl + '/#/successNoPayment', "_self");
        } else {
            this.submitClick();
        }
    })
    .catch(error => {
        console.error('Ошибка при получении данных о пользователе', error);
    });
}


    render() {
        const { pdfUrl, loadingPDF, error, isInvoicePdfFetched, no_payment } = this.state;

        return (
            <div>
                <h2 style={{ textAlign: "center" }}>
                    РАСПОРЯЖЕНИЕ НА ВЫДАЧУ ВЫПИСКИ / СПРАВКИ
                </h2>
                <div>
                    {error && <div>{error}</div>}
                    {pdfUrl && (
                        <div>
                            <iframe
                                title="PDF Preview"
                                src={pdfUrl}
                                width="100%"
                                height="800px"
                                style={{ border: "none" }}
                            />
                        </div>
                    )}
                </div>
                <div style={styles.footer}>
                    <div style={{ textAlign: "center" }}>
                        <div className="button3-container">
                            {isInvoicePdfFetched ? (
                                <button
                                    className="button3"
                                    onClick={this.submClick.bind(this)}
                                >
                                    {(no_payment === 1 || no_payment === 2) ? 'Получить' : 'Оплатить'}
                                </button>
                            ) : (
                                <button
                                    className="button3"
                                    disabled={true}
                                >
                                    Создание счета, пожалуйста подождите
                                </button>
                            )}
                        </div>
                    </div>
                    {!isInvoicePdfFetched && <Loading />}
                </div>
            </div>
        );
    }
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        height: "100vh",
        backgroundColor: "#f0f0f0",
        padding: "20px",
        boxSizing: "border-box",
    },
    pdfWrapper: {
        width: "80%",
        maxWidth: "800px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        backgroundColor: "#fff",
        overflow: "hidden",
    },
    footer: {
        display: "center",
        justifyContent: "space-between",
        padding: "10px 0",
    },
};

export default PdfSignedPreview;