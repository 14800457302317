import React from 'react';
import SessionAlert from './SessionAlert';

class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      sessionStart: 0,
      sessionEnd: 0,
      session: false
    };
    this.restart = this.restart.bind(this);
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
    var start = this.state.date;
    var end = new Date(start.getTime() + 25*60000);
    this.setState({
      sessionStart: start.toLocaleTimeString(),
      sessionEnd: end.toLocaleTimeString(),
      session: this.props.logged_in
    })
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    if(this.state.session===true){
      this.setState({
        date: new Date()
      });
      if(this.state.date.toLocaleTimeString() > this.state.sessionEnd){
        this.setState({
          session: "окончена"
        })
      }
    }
  }

  restart() {
    var start = this.state.date;
    var end = new Date(start.getTime() + 25*60000);
    this.setState({
      sessionStart: start.toLocaleTimeString(),
      sessionEnd: end.toLocaleTimeString(),
      session: true
    })
  }

  render() {
    if(this.state.session===true  ){
      return (
        <> </>
      );
    }
    else if (this.state.session==="окончена"){
      return (
        <SessionAlert restart={this.restart} logout={this.props.logout}/>
      );
    }
    else{
      return(
        <> </>
      );
    }

  }
}

export default Clock;
