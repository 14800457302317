import AriaModal from "react-aria-modal";
const React = require('react');


class SessionAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalActive: false,
    };

    this.activateModal = this.activateModal.bind(this);
    this.deactivateModal = this.deactivateModal.bind(this);
  }

  activateModal = () => {
    this.setState({ modalActive: true });
  };

  deactivateModal = () => {
    this.setState({ modalActive: false });
  };

  escFunction(event){
    if(event.keyCode === 27) {
      event.stopImmediatePropagation();
    }
  }

  async componentDidMount() {
    this.setState({
      modalActive:  true
    });
    this.props.logout();
  }


  render() {
    const modal = this.state.modalActive
      ? <AriaModal
          titleId="demo-two-title"
          onExit={this.deactivateModal}
          underlayClickExits={false}
          verticallyCenter={true}
        >
            <form>
              <div id="demo-two-modal" className="modal">
                <header className="modal-header">
                  <h2 id="demo-two-title" className="modal-title">
                      Время сессии истекло. Для продолжения работы необходимо снова выполнить вход в систему.
                  </h2>
                  <div className="modal-body">
                  <div>
                    <button type="submit" className="sessionSubmit" onClick={this.deactivateModal}>
                      Ок
                    </button>
                  </div>

                  </div>

                </header>
              </div>
              </form>

        </AriaModal>
      : false;

    return (
      <div>
        <button style={{display: 'none' }} onClick={this.activateModal}>
          Click me
        </button>
        {modal}
      </div>
    );
  }
}
export default SessionAlert;
