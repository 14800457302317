import React from "react";
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";

import Info from "./info";
import Portfolio from "./portfolio";
import Profile from "./profile";
import PropTypes from 'prop-types';
import LoginForm from './LoginForm';
import User_Profile from "./user_profile";
import Conf_policy from "./conf_policy";
import Exchange from "./exchange";
import Payment from "./payment";
import Success from "./success";
import SuccessNoPayment from "./successNoPayment";
import Transactions from "./transactions";
function Nav(props) {
  const logged_out_nav = (
      <button id="loginBttn" style={{display: 'none' }} onClick={() => props.display_form('login')}>Вход</button>
  );

  const logged_in_nav = (

    <HashRouter>
      <div>
      <div  className="background">
        <div><button id="logoutBttn" onClick={props.handle_logout} className="button button1">Выйти</button></div>
        <div className="header">
            <h2 className="changeableHeading">Личный кабинет акционера</h2>
            <div align = "right" className="changeableText">{props.personname} </div>
        </div>
      </div>
        <ul className="header ul">
          <li className="li"><NavLink to="/">Запрос на информацию</NavLink></li>
          <li className="li"><NavLink to="/transactions">Проведенные операции</NavLink></li>
          <li className="li"><NavLink to="/portfolio">Портфель</NavLink></li>
          <li className="li"><NavLink to="/profile">Анкета</NavLink></li>
          <li className="li"><NavLink to="/settings">Настройки</NavLink></li>
          <li className="li"><NavLink to="/conf_policy">Политика конфиденциальности</NavLink></li>
          <li className="li"><NavLink to="/payment"> Оплата</NavLink></li>
          <li className="li"><NavLink to="/Exchange">Возврат и контакты</NavLink></li>

        </ul>
        <div className="content">
          <Route exact path="/" component={Info} key="info"/>
          <Route path="/transactions" component={Transactions} />
          <Route path="/portfolio" component={Portfolio}/>
          <Route path="/profile" component={Profile}/>
          <Route path="/settings" component={User_Profile}/>
          <Route path="/esia_ok" component={LoginForm}/>
          <Route path="/Exchange"  component={Exchange}/>
          <Route path="/payment"  component={Payment}/>
          <Route path="/conf_policy"  component={Conf_policy}/>
          <Route path="/success" component={Success}/>
          <Route path="/successNoPayment" component={SuccessNoPayment}/>
        </div>
      </div>

    </HashRouter>
  );

  return <div>{props.logged_in && props.username !==undefined? logged_in_nav : logged_out_nav}</div>;


}

export default Nav;

Nav.propTypes = {
  logged_in: PropTypes.bool.isRequired,
  display_form: PropTypes.func.isRequired,
  handle_logout: PropTypes.func.isRequired
};
