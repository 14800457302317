import React, { Component } from "react";
import './Conf_policy.css'
import { css } from "@emotion/core";
class Conf_policy extends Component{
    state = {
        data: []
    };


    render() {

            return (
                <div className="Conf_policy">
                    <h1 className="header">Политика&nbsp;конфиденциальности</h1>

                        <div className="pdf_info"><a
                            href="https://www.vrk.ru/fs/sources/59/42/61/b3/82e01daf9e4f269dbba91dae.pdf"
                            className="tit" download="Политика обработки и защиты ПДН.pdf" target="_blank"
                            rel="noopener"><span>Политика обработки и защиты ПДН</span></a>
                        <p><img className='img' src="https://cdn-icons-png.flaticon.com/512/337/337946.png" />&nbsp;Действует с&nbsp;15.05.2019&nbsp;(202.85 Кб)</p>
                        </div>

                </div>
            );

        }

}
export default Conf_policy;