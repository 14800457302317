import AriaModal from "react-aria-modal";
import DemoTwo from './Demon'
import CryptoJS from 'crypto-js';
import formatString from 'format-string-by-pattern';
import close from "./images/close.png"


const React = require('react');

class PhoneForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalActive: true,
      email: "",
      status: "",
      code:"",
      input_hash:"",
      previous:"",
      username: '',
      password: ''
    };
    this.close = this.close.bind(this)
    this.activateModal = this.activateModal.bind(this);
    this.deactivateModal = this.deactivateModal.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkCode = this.checkCode.bind(this);
    this.logIn = this.logIn.bind(this);
  }

  activateModal = () => {
    this.setState({ modalActive: true });
    console.log(this.props);
  };

  deactivateModal = () => {
    this.setState({ modalActive: false });
    this.close();
  };

 close = async () => {
  const SELF_ADDRESS = process.env.REACT_APP_SELF_ADDRESS;
  window.location.href = `${SELF_ADDRESS}`;
  //self_address = lk.vrk.ru
}
  async handleSubmit(event, data) {
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    var response = await fetch(`${API_ADDRESS}drop_psswd/?email=`+this.state.email, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    var response1 = await response.json();
    if(response1.code === "0"){
      this.setState({
        status: "invalid"
      });
    }
    else{
      var selectedCode = response1['code'].slice(13, 18);
      this.setState({
        status: "valid",
        code: selectedCode
      });
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    },
    );
    if(name === "email"){
        this.setState({
        email:value
      });
         }
  }

  checkCode(){
    if (this.state.code === this.state.input_hash){
      return true;
    }
    return false;
  }

  async logIn(){

    function encode(token1, iv){

      var tokenPython = atob(token1);

      var symmetricKey = "NswBgv3KOlaaOt8b";

      var key = CryptoJS.enc.Latin1.parse( CryptoJS.enc.Latin1.stringify( CryptoJS.SHA256( symmetricKey )));
      //var iv  = CryptoJS.lib.WordArray.random( 16 );
      //var encrypted = CryptoJS.AES.encrypt( dataToEncrypt,
      //                                       key,
      //                                       { iv: iv, format: CryptoJS.format.OpenSSL }
      //                                     ).ciphertext.toString(CryptoJS.enc.Latin1);
      var decrypted = toByteArray( CryptoJS.AES.decrypt( { ciphertext: CryptoJS.enc.Latin1.parse(tokenPython) },
                                               key,
                                               { iv: iv, padding: CryptoJS.pad.NoPadding }
                                           ).toString(CryptoJS.enc.Latin1) );
      var answer = decrypted.slice(0, decrypted.length-1)
      //console.log(answer);
      //console.log(bArr);
      var buffer = new Uint8Array(answer)

      //console.log(buffer);
      return _arrayBufferToBase64(buffer);
    };
    function _arrayBufferToBase64( buffer ) {
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }

    function toByteArray(str) {
       var bArr = [];
       for (var i=0; i<str.length; i++) {
         bArr.push( str.charCodeAt(i) );
       }
       return bArr;
    }
    const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
    const response = await fetch(`${API_ADDRESS}login_user/?email=`+this.state.email , {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    const answer = await response.json();
    var salt = CryptoJS.enc.Latin1.parse(atob(answer['iv']));
    var encodedToken = atob(encode(answer['token'], salt));

    localStorage.setItem('token', encodedToken);
    //const SELF_ADDRESS = process.env.SELF_ADDRESS;
    //window.location.href = `${SELF_ADDRESS}`;
  }

  async componentDidMount() {
  }

  render() {
    const modal = this.state.modalActive
      ? <AriaModal
          titleId="demo-two-title"
          onExit={this.deactivateModal}
          underlayClickExits={false}
          verticallyCenter={true}
        >

            <form onSubmit={event => this.handleSubmit(event, this.state)}>
            <img className="close-bttn img" onClick={this.deactivateModal} height="35x" width="35px" align="right" src={close} alt="close"/>
              <div id="demo-two-modal" className="modal">
                <header className="modal-header">
                  <h2 id="demo-two-title" className="modal-title">
                    Введите ваш email. На него будет отправлен код авторизации.
                  </h2>
                  <div className="modal-body">

                      <div className="col-75 raw-4">
                          <p></p>
                      </div>
                      <div className="col-25 raw-4">
                          <p></p>
                      </div>

                      <div className="col-50 raw-4">
                          <label htmlFor="Select" className="radioText" > Введите ваш email</label>
                      </div>
                      <div className="col-50 raw-4">
                      <input type="text" name="email" id="email" onChange={this.handleChange}/>
                      </div>
                  </div>

                </header>

                <footer className="modal-footer">
                  <button type="submit" onClick={() => this.deactivateModal}>
                    Отправить
                  </button>
                  {this.state.status==="invalid" ? (
                    <p className="esiaText"> Введённый email не существует. </p>
                  ):(
                    <>
                    </>
                  )
                  }
              </footer>
              </div>
              </form>

        </AriaModal>
      : false;
    if(this.state.status==="valid"){
      return (
        <div>
        <DemoTwo callback={this.handleChange} hash={this.checkCode} childOnClick={this.logIn} refresh={this.checkCode} onClick={this.checkCode} isActive={true} closeFunc={this.close}/>
        </div>
      );
    }
    else{
      return (
        <div>
          {modal}
        </div>
      );
    }
  }
}
export default PhoneForm;
